// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//Dependencies added for cocoon
var jQuery = require("jquery")
require("@nathanvda/cocoon")

//libreria cargada o estáticamente (buscando el archivo) --> import "../select2/select2.min"
// O buscando en los modulos npm:
import 'select2'

//Cargamos los stylesheets estaticamente -> import (Desde la carpeta javascript): '../stylesheets/application'
// O buscando en los modulos:
import 'select2/dist/css/select2'
import '@ckeditor/ckeditor5-build-classic'

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
import '../active_storage/direct_uploads'
import '../stylesheets/direct_uploads'
//import 'bulma/bulma.sass'
import '../stylesheets/application'

//import "datatables-bulma/css/dataTables.bulma.css"
//import "datatables-bulma/js/dataTables.bulma"
//import "serve-index/public/style.css"
import 'textfit'
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("trix")
require("@rails/actiontext")